import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labsComponents from "vuetify/labs/components";
import VueCookies from "vue-cookies";
import axios from "axios";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import "vue-loaders/dist/vue-loaders.css";
import VueLoaders from "vue-loaders";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "moment/dist/locale/ja";
import VueScrollingTable from "vue-scrolling-table";
import "/node_modules/vue-scrolling-table/dist/style.css";
import * as Sentry from "@sentry/vue";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import { useAppLabelsStore } from "./stores/appLabels.js";

const app = createApp(App);
app.use(VueCookies);
app.use(VueLoaders);

app.config.globalProperties.$axios = axios;

app.config.productionTip = false;

app.component("Cropper", Cropper);
app.component("vue-date-picker", Datepicker);
app.component("vue-time-picker", Datepicker);
app.component("vue-scrolling-table", VueScrollingTable);

Sentry.init({
  app,
  enabled: import.meta.env.VITE_USE_SENTRY?.toLowerCase() == "true",
  environment: `vManagement App(${window.location.hostname})`,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracePropagationTargets: import.meta.env.VITE_TRACE_ORIGINS?.split(","),
  // Performance Monitoring
  tracesSampleRate: 0.1,
  // Session Replay
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
});

import LangSetting from "../language/setting.js";
// formatterはv9.0でdeprecatedになったが、次のメジャーバージョンでサポート予定
// https://vue-i18n.intlify.dev/guide/migration/breaking.html#remove-custom-formatter
export const i18n = createI18n({
  // locale: 'ja',
  // TODO 可能であればCommonControl.getLangCodeで取得出来るようにする
  locale:
    app.$cookies.get("language") != null ? app.$cookies.get("language") : "ja",
  messages: LangSetting.data().language,
  globalInjection: false, // $t関数などをグローバルに持たせない
  legacy: false, // 新しい形式のAPIを利用する
});
app.use(i18n);

import mitt from "mitt";
const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$t = (key, value) => {
  const store = useAppLabelsStore();
  let values = store.appLabels;
  if (value) {
    values = Object.assign({}, values, value);
  }
  return i18n.global.t(key, values);
};

app.use(router);

const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  directives,
});
app.use(vuetify);

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
app.use(pinia).mount("#app");
